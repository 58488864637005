@import '~common/ui_imports';

.header {
  margin-bottom: var(--synth-spacing-8);
  width: $card-width-sm;
}

.headingText {
  margin-bottom: var(--synth-spacing-1);
}

.text {
  margin-bottom: var(--synth-spacing-3);
}
