.surveyFormCard :global(.FormGroup) {
  margin: var(--synth-spacing-4) 0;
}

.surveyFormCard :global(.FormGroup:first-child) {
  margin-top: 0;
}

.surveyFormCard :global(.FormGroup:last-child) {
  margin-bottom: 0;
}
