@import '~common/ui_imports';

.Toggle {
  @include font-type-30;
  height: 100%;
  margin-left: var(--synth-spacing-2);

  .Accept {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 0.3rem;

    &.reject {
      background: var(--ux-red-100);
    }

    &.accept, &.mayForPickOne {
      background: var(--ux-green-100);
    }

    &.may {
      background: var(--ux-gray-300);
    }
  }
}

.Input {
  text-align: center;
  height: 100%;
}
