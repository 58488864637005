@import '~common/ui_imports';

.Divider {
  border-bottom: .0625rem solid var(--ux-gray-400);
}

.Header {
  margin-top: 3.5rem;

  @include media-breakpoint-down(sm) {
    margin: 3.5rem 1.25rem var(--synth-spacing-3);
  }
}
