@import '~common/ui_imports';

.StudyDetailsAccordion {
  &__collapse {
    border-top: none;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    gap: .25rem 0;

    &__line-item {
      margin-right: 1rem;
    }
  }

  &__description {
    white-space: pre-wrap;
  }
}
