.Answer:not(:last-child) {
  margin-bottom: var(--synth-spacing-1);
}

.QuestionTitle {
  margin-bottom: var(--synth-spacing-1);
}

.QuestionGroup:not(:last-child) {
  margin: var(--synth-spacing-2) 0 var(--synth-spacing-4);
}
