.optInFormPage {
  padding: var(--synth-spacing-12) 0 300px;
}


.consentNotice {
  background-color: var(--ux-gray-200);
  border-radius: var(--ux-border-radius);
  padding: var(--synth-spacing-4);
  width: 100%;
}

.consentNoticeBody {
  max-height: 10rem;
  overflow-y: auto;
  padding-left: var(--synth-spacing-2);

  p {
    margin: 0;
  }
}

.submitButton {
  display: block;
  margin: auto;
}

@media (max-width: 991px) {
  .submitButton {
    width: 100%;
  }
}
