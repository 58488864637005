@import '~common/ui_imports';

.SuccessIcon {
  color: var(--ux-emerald);
  font-size: 5rem;
  padding-bottom: var(--synth-spacing-4);

  @include media-breakpoint-down(sm) {
    font-size: 4rem;
  }
}

.RestartButton {
  margin-top: var(--synth-spacing-4);

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
