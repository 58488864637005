.description {
  ul p,
  ol p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.header {
  padding-top: var(--synth-spacing-9);
}

.title {
  color: var(--ux-gray-800);
  margin-bottom: var(--synth-spacing-4);
}

.logoContainer {
  margin-bottom: var(--synth-spacing-9);
}

.logo {
  max-height: 90px;
  max-width: 240px;
  margin-bottom: var(--synth-spacing-1);
}

.descriptionAccordionCollapse {
  border-top: none;
}
