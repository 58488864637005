.GridQuestion {
  width: 100%;
  word-break: break-word;
  white-space: pre-wrap;

  :global .CheckboxButtonGroup {
    flex: 1 1;
    margin: 0;
  }

  .InputCell {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1 1 0;
    justify-content: center;
    margin: 0;

    &:hover {
      background-color: var(--ux-blue-100);
    }

    :global input[type='radio'],
    :global input[type='checkbox'] {
      margin: 0;
    }
  }
}

.AnswerTitleCell {
  flex: 1 1 0;
  margin: var(--synth-spacing-4) 0 var(--synth-spacing-2);
  text-align: center;
}

.QuestionTitleCell {
  flex: 0 0 6rem;
  padding: var(--synth-spacing-3) 0;
}

.Title {
  white-space: pre-wrap;
}
