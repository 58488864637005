@import '~common/ui_imports';

$col-sm__question__text: 6;
$col-lg__question__text: 7;
$col-xl__question__text: 8;

.SurveyBuilderAnswer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: .5rem;

  &.editing {
    @include make-row;

    align-items: center;
    flex-direction: column;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      align-items: flex-start;
      flex-direction: row;
    }

    + #{&} {
      margin-top: .5rem;
    }
  }

  &__actions {
    margin-left: 0.75rem;
  }

  &__text {
    @include make-col-ready;

    display: flex;

    align-items: center;
    flex-flow: row wrap;
    justify-content: flex-start;

    // This is a bit convoluted but it makes it line up w/ the question text input
    @include media-breakpoint-up(sm) {
      @include make-col($col-sm__question__text);
    }

    @include media-breakpoint-up(md) {
      @include make-col($col-lg__question__text);
    }

    @include media-breakpoint-up(lg) {
      @include make-col($col-xl__question__text);
    }

    // This may seem excessive but it makes it so we can standardize the size of check boxes
    $checkbox-width: var(--synth-spacing-6);
    .checkbox-wrapper {
      margin-right: var(--synth-spacing-2);

      input[type="checkbox"], input[type="radio"] {
        margin: 0;
      }
    }

    .invalid-feedback {
      margin-left: $checkbox-width;
    }

    input.form-control {
      display: inline-block;
      width: calc(100% - #{$checkbox-width});
    }
  }

  &__controls {
    margin-top: .25rem;
    padding: 0 1rem;
    text-align: center;
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    @include media-breakpoint-up(sm) {
      @include make-col-ready;

      justify-content: flex-start;
      margin-top: 0;
      text-align: left;
      width: auto; // Remove 100% width from col - just need the padding
    }

    .SingleSelect {
      margin-left: 1rem;
      text-align: left;
      width: 8rem;

      @include media-breakpoint-up(sm) {
        margin: 0;
      }
    }
  }

  input[type="checkbox"], input[type="radio"] {
    margin: .25rem .5rem .25rem 0;
  }
}
