@import '~common/ui_imports';

.divider {
  border-bottom: .0625rem solid var(--ux-gray-400);
  margin: var(--synth-spacing-8) auto var(--synth-spacing-6);
  max-width: 512px;
  width: 100%;
}

.header {
  margin-bottom: var(--synth-spacing-10);
}

.heading {
  margin-top: var(--synth-spacing-12);

  @include media-breakpoint-down(sm) {
    margin: var(--synth-spacing-12) var(--synth-spacing-6) var(--synth-spacing-3);
  }
}
