@import '~common/ui_imports';

.SectionControls {
  display: flex;
  justify-content: space-between;
  margin-top: var(--synth-spacing-4);
  padding-bottom: var(--synth-spacing-4);

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;

    :global .btn + .btn {
      margin-top: var(--synth-spacing-2);
    }
  }
}
