@import '~common/ui_imports';

.PreviewBanner {
  @include font-type-30;

  background: var(--ux-navy-100);
  border-radius: var(--ux-border-radius);
  color: var(--ux-navy-900);
  margin-top: var(--synth-spacing-6);
  padding: var(--synth-spacing-4) 2.5rem;
  text-align: center;
  width: 100%;

  @include media-breakpoint-down(sm) {
    @include font-type-20;
  }
}

.Header {
  @include font-type-30--bold;

  @include media-breakpoint-down(sm) {
    @include font-type-20--bold;
    line-height: var(--synth-spacing-6);
  }
}

.Icon {
  @include media-breakpoint-down(sm) {
    @include font-type-30--bold;
  }
}

.MobileBreak {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
