@import '~common/ui_imports';

.PhoneNumber {
  width: 100%;

  @include media-breakpoint-up(sm) {
    display: inline-flex;

    &__phone-number {
      width: 100%;
    }

    &__country-code {
      margin-right: 0.5rem;
      width: 100%;
    }
  }
}
