@import '~common/ui_imports';

.page {
  display: flex;
  position: relative;

  &.padded > main {
    padding: 0 var(--page-padding-x);
  }

  &:not(.padded) > main {
    padding: 0;
  }

  > :global(.overlay) {
    height: 100vh;
    position: fixed;
  } 
  
  &.centered > main {
    max-width: var(--page-centered-width);
    margin-left: auto;
    margin-right: auto;
  }
}

.page.fullHeight > main {
  @include ui-new-nav-full-view-height;
}

@include media-breakpoint-up(md) {
  .page > main {
    // Ideally this wouldn't be necessary but some sub components are setting width explicitly.
    max-width: calc(100vw - var(--page-initial-sidebar-width));
  }
}

@include media-breakpoint-down(md) {
  .page {
    display: block;
  }

  .page > main {
    min-height: calc(100vh - var(--navigation-mobile-header-height) - var(--navigation-mobile-footer-height));
  }
}
