@import '~common/ui_imports';

$col-sm__question__text: 6;
$col-lg__question__text: 7;
$col-xl__question__text: 8;
$highlight-box-shadow-size: 3px;

.SurveyBuilderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--synth-spacing-2);

  &__text {
    flex-basis: 100%;
    display: flex;
    justify-content: flex-start;
    margin-right: var(--synth-spacing-1);

    textarea {
      font-size: 0.875rem;
    }

    &__form {
      width: 100%;
      margin-right: 1rem;
      margin-left: 7px;

      span {
        margin-left: var(--synth-spacing-4);
      }

      .invalid-feedback {
        margin-left: var(--synth-spacing-4);
      }
    }

    .form-control.question {
      margin: 0 var(--synth-spacing-4);
    }

    &__answer {
      width: 100%;
      display: flex;

      .drag-handle-wrapper {
        flex: 0 0 var(--synth-spacing-4);
        color: var(--ux-gray-400);

        svg {
          margin-top: 13px;
          display: none;
        }
      }

      &:hover {
        .drag-handle-wrapper {
          cursor: grab;
          svg {
            display: inline-block;
          }
        }
      }

      .checkbox-wrapper {
        flex: 0 0 29px;

        input[type="checkbox"], input[type="radio"] {
          margin-top: 13px;
          margin-right: var(--synth-spacing-2);
        }
      }

      .answer-wrapper {
        flex: 1;
        justify-content: flex-start;

        textarea {
          resize: none;
          white-space: nowrap;
          overflow: hidden;
          height: 38px;
        }

        p {
          margin-top: var(--synth-spacing-3);
        }
      }
    }
  }

  &__controls {
    flex-basis: 35%;
    justify-content: space-between;
  }

  &__actions {
    flex: 0 0 4.5rem;
  }

  &.other {
    margin-top: -var(--synth-spacing-2);

    textarea {
      height: 37px;
    }
  }

  &.second-line {
    margin-top: -20px;
  }
}

.SurveyBuilderQuestion {
  $block: #{&};
  margin-top: 1rem;

  background-color: var(--ux-gray-100);
  border-radius: .25rem;
  position: relative;
  outline-offset: 1px;

  .Tooltip__icon {
    margin-top: 0.35rem;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &__Content {
    padding: var(--synth-spacing-6);
  }

  &__drag-handle {
    position: absolute;
    top: var(--synth-spacing-7);
    left: var(--synth-spacing-7);
    color: var(--ux-gray-800);
    background-color: transparent;
    transition: background-color $duration-short ease;
  }

  &__RowLabelContainer {
    div {
      @include make-col-ready;
    }

    > div:nth-of-type(1) {
      @include media-breakpoint-up(sm) {
        @include make-col($col-sm__question__text);
      }

      @include media-breakpoint-up(md) {
        @include make-col($col-lg__question__text);
      }

      @include media-breakpoint-up(lg) {
        @include make-col($col-xl__question__text);
      }
    }

    > div:nth-of-type(2) {
      @include make-col;
    }
  }

  &__text {
    @include make-col-ready;

    @include media-breakpoint-up(sm) {
      @include make-col($col-sm__question__text);
    }

    @include media-breakpoint-up(md) {
      @include make-col($col-lg__question__text);
    }

    @include media-breakpoint-up(lg) {
      @include make-col($col-xl__question__text);
    }

    span {
      font-weight: $font-weight-bold;
    }

    padding: 0 0 0 var(--synth-spacing-8);
  }

  &__top-controls {
    align-items: center;
    align-self: flex-start;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-top: -0.25rem;

    @include make-col-ready;

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;

      @include make-col(12 - $col-sm__question__text);
    }

    @include media-breakpoint-up(md) {
      @include make-col(12 - $col-lg__question__text);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(12 - $col-xl__question__text);
    }

    .controls-group {
      display: flex;
      justify-content: flex-end;
    }

    .btn-group {
      align-items: center;
    }
  }

  &__type {
    flex-grow: 1;
    margin: 0 var(--synth-spacing-3);
    max-width: 10rem;
    min-width: 7.5rem;

    &:active, &.active:hover, &.active:focus {
      background-color: $ux-primary;
    }

    @include media-breakpoint-up(sm) {
      max-width: 100%;
    }
  }

  &__answers {
    @include make-col-ready;

    margin-top: 1rem;
    margin-bottom: 1rem;

    .form-control {
      &:disabled {
        background-color: var(--ux-gray-200);
      }
    }
  }

  &.has-errors {
    box-shadow: 0 0 0 $highlight-box-shadow-size var(--ux-red);
  }

  &.editing {
    #{$block}__top-controls {
      margin-top: 0;
    }

    textarea.form-control {
      padding: var(--synth-spacing-2);
    }

    .errors {
      margin-top: var(--synth-spacing-4);
      padding: var(--synth-spacing-4);

      background-color: lighten($ux-error, 40%);
      color: $ux-error;
    }
  }

  &.dragging {
    transition: box-shadow $duration-long ease-in-out;
    box-shadow: $ux-draggable-boxshadow;
  }
}
