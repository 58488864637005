.SurveyBuilder {
  &__no-sections {
    text-align: center;
  }

  .required-field::after {
    content: '*';
  }

  .has-error {
    .required-field::after {
      color: theme-color("danger");
    }
  }

  .form-section {
    &__controls {
      &__menu-icon {
        margin-left: auto;
      }
    }
  }

  .skip-logic-summary-container {
    &--editing {
      margin-top: 1rem;
    }

    .skip-logic-summary {
      background: var(--ux-gray-200);
      border-color: var(--ux-gray-400);
      margin: 0 0 .5rem;
    }
  }
}
