@import '~common/ui_imports';

$removeButtonWidth: 2.5rem;

.gridRow {
  color: var(--ux-gray-700);
  margin: var(--synth-spacing-2) 0;

  .boxRow {
    display: flex;
  }

  .answerRow {
    display: flex;
    align-items: flex-end;
  }

  .box2 {
    width: 50%;
  }

  .box3 {
    width: 33%;
  }

  .box4 {
    width: 25%;
  }

  .box5 {
    width: 20%;
  }

  .box6 {
    width: 16.6%;
  }

  .box7 {
    width: 14.25%;
  }

  .box8 {
    width: 12.5%;
  }

  .removeButton {
    display: none;
  }

  .gridAnswer {
    margin-left: var(--synth-spacing-2);
  }

  .gridInput {
    position: relative;

    .gridInputTextArea {
      @include font-type-30;
      box-shadow: none;
      overflow: hidden;
      resize: none;
    }

    :global textarea.form-control {
      padding: var(--synth-spacing-2) $removeButtonWidth var(--synth-spacing-2) var(--synth-spacing-2);
    }

    &:hover {
      .removeButton {
        display: inline-block;
      }
    }
  }

  .gridTitleNonEdit {
    word-break: break-word;
    margin: var(--synth-spacing-2) 0;
  }
}

.addButtons {
  margin-top: var(--synth-spacing-4);

  .addButton {
    border-color: var(--ux-gray-700);
    color: var(--ux-gray-700);
    font-weight: inherit;
    margin-right: var(--synth-spacing-2);
    padding-bottom: var(--synth-spacing-1);
    padding-top: var(--synth-spacing-1);
  }
}

.qualificationInstructions {
  color: var(--ux-gray-700);
  margin: var(--synth-spacing-6) 0;
}
