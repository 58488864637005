.skipLogicSummary {
  background: var(--ux-gray-100);
  border: 1px solid var(--ux-gray-300);
  border-radius: .25rem;
  color: var(--ux-gray-800);
  margin-left: 10rem;
  padding: 1rem;

  @media (max-width: 576px) {
    margin-left: 0;
  }
}

.skipLogicSummaryTitle {
  font-weight: var(--synth-font-weight-bold);
  margin-bottom: 0.5rem;
}

.skipLogicSummaryLogic {
  margin-bottom: .5rem;
  margin-left: .45rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.skipLogicSummaryLogicHeader {
  margin: 0 0 .25rem .7rem;
}

.skipLogicSummaryLogicList {
  color: var(--ux-gray-800);
  list-style: disc;
  margin-bottom: 0;
}
