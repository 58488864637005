.SurveyBuilderSection {
  position: relative;
  margin: var(--synth-spacing-6) auto;

  .SectionDropdown {
    position: absolute;
    right: var(--synth-spacing-4);
    top: var(--synth-spacing-3);
  }

  .errors {
    color: var(--ux-red);
  }

  :global .AccordionCollapse {
    border-top: none;

    .AccordionCollapse__container {
      padding: 0 var(--synth-spacing-6);

      .form-section {
        border: none;
        padding: 0;
        box-shadow: none;
      }
    }
  }
}
