@import "~common/ui_imports";

.SubmitButton {
  display: block;
  margin: auto;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
