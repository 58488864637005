@import '~common/ui_imports';

.SkipLogicModal {
  &__clarifier {
    @include font-type-30;
    margin-bottom: .5rem;
  }

  &__empty-text {
    margin-bottom: 0;
  }

  &__skip-logic {
    $prefix-width: 5rem;
    $spacing: .5rem;
    $remove-button-width: 6rem;
    $controls-width: calc(100% - #{$prefix-width} - #{2 * $spacing} - #{$remove-button-width});

    &.form-group {
      margin-bottom: 0;
      padding: 1rem 0;
    }

    &__row {
      display: flex;

      align-items: center;
      flex-direction: row;
      justify-content: flex-start;

      &:not(:first-child) {
        margin-top: 1rem;
      }

      &__prefix {
        width: $prefix-width;
        margin-right: $spacing;
      }

      &__control, &__controls {
        width: $controls-width;
      }

      &__control {
        margin-right: 0.5rem;
      }

      &__controls {
        display: inline-flex;

        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        .single-select {
          width: 100%;
        }

        .form-select:not(:first-child) {
          margin-left: $spacing;
        }
      }

      .btn-add, .btn-remove {
        margin-left: $spacing * 1.5;
        padding: .125rem;
        width: 1rem;
      }

      .btn-outline-primary {
        margin-left: $spacing;
        width: $remove-button-width;
      }

      .btn-group {
        display: flex;
        align-items: center;
      }
    }
  }

  .btn-link {
    margin-top: 0.5rem;
  }
}
